import React, { useEffect, useState } from "react";
import JiraIcon from "@/icons/JiraIcon";
import TrashIcon from "@/icons/TrashIcon";
import Empty from "@/components/ui/Empty";
import DocTextIcon from "@/icons/DocTextIcon";
import TicketFull from "./TicketFull";
import SendToJiraModal from "@/components/modals/SendToJiraModal";
import DashboardWrapper from "@/components/ui/DashboardWrapper";
import { Link } from "react-router-dom";
import CSVIcon from "@/icons/CSVIcon";
import WebAppService from "@/services/WebAppService";
import BackIcon from "@/icons/BackIcon";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "@/components/LoadingComponent";
import ErrorToast from "@/components/modals/ErrorToast";
import ConfirmDelete from "@/components/modals/ConfirmDelete";
import SuccessToast from "@/components/modals/SuccessToast";
import { useProjects } from "../../../context/ProjectsContext";
import { useToast } from "../../../context/ToastContext";

export default function ProjectBody({
	projectDetails,
	showTicketPage,
	setShowTicketPage,
	loading,
}) {
	const [showJiraModal, setShowJiraModal] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [csvData, setCsvData] = useState("");
	const [projectId, setProjectId] = useState("");
	const navigate = useNavigate();
	const { refetchProjects } = useProjects();
	const { addToast } = useToast();

	const openJiraModal = () => {
		setShowJiraModal(true);
	};

	const closeJiraModal = () => {
		setShowJiraModal(false);
	};

	const tasks = projectDetails?.tasks || [];

	const taskId = projectDetails?.id;

	const [newTasks, setNewTasks] = useState(tasks);

	useEffect(() => {
		setProjectId(taskId);
	}, [taskId]);

	const getNewTasks = async () => {
		try {
			const getProjectsDetailsResponse = await WebAppService.getProjectDetails(
				taskId
			);

			setNewTasks(getProjectsDetailsResponse?.data?.tasks);
		} catch (error) {
			setErrorMessage(error?.response?.data?.error);
			addToast("Error fetching tasks", "error", 3000);
		}
	};

	useEffect(() => {
		if (taskId) {
			getNewTasks();
		}
	}, [tasks]);

	const projectName = projectDetails?.name;

	const lastDotIndex = projectName?.lastIndexOf(".");

	// Check if "." exists in the string and get the substring before it
	const formattedProjectName =
		lastDotIndex !== -1 ? projectName?.substring(0, lastDotIndex) : projectName;

	const createdAtDate = new Date(projectDetails?.created_at);
	const now = new Date();
	const timeDifference = now - createdAtDate;
	const timeAgo = getTimeAgoString(timeDifference);

	function getTimeAgoString(milliseconds) {
		const seconds = Math.floor(milliseconds / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		if (days > 0) {
			return `${days} day${days !== 1 ? "s" : ""} ago`;
		} else if (hours > 0) {
			return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
		} else if (minutes > 0) {
			return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
		} else {
			return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
		}
	}

	const handleDownloadCSV = () => {
		// CSV header
		const headers = ["Title", "Description", "Acceptance Criteria"];

		// CSV data
		const csvRows = tasks.map((task) => [
			`"${task.title.replace(/"/g, '""')}"`,
			`"${task.description.replace(/"/g, '""')}"`,
			`"${task.acceptance_criteria.replace(/"/g, '""')}"`,
		]);

		// Joining header and data rows
		const csvContent =
			"data:text/csv;charset=utf-8," +
			headers.join(",") +
			"\n" +
			csvRows.map((row) => row.join(",")).join("\n");

		// Create a link element
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${formattedProjectName}_tasks.csv`);

		// Append the link to the body
		document.body.appendChild(link);

		// Trigger the download
		link.click();

		// Clean up
		document.body.removeChild(link);
	};

	const handleBack = () => {
		window.history.back();
	};

	const openConfirmDelete = () => {
		setConfirmDelete(true);
	};
	const closeConfirmDelete = () => {
		setConfirmDelete(false);
	};

	const handleDeleteTicket = (indexToDelete) => {
		const updatedTasks = [...newTasks]; // Create a new array
		updatedTasks.splice(indexToDelete, 1);
		setNewTasks(updatedTasks);
	};

	const handleDeleteProject = async () => {
		setIsDeleting(true);

		try {
			const deleteProjectResponse = await WebAppService.deleteProjectById(
				taskId
			);

			if (deleteProjectResponse?.success) {
				setTimeout(() => {
					setIsDeleting(false);
					setConfirmDelete(false);
				}, 2000);

				setTimeout(() => {
					addToast("Project deleted successfully", "success", 3000);
					// setShowSuccessToast(true);
				}, 3000);

				refetchProjects();

				setTimeout(() => {
					// setShowSuccessToast(false);
					window.history.back();
				}, 3000);
			}
		} catch (error) {
			setTimeout(() => {
				setIsDeleting(false);
			}, 2900);

			setTimeout(() => {
				setConfirmDelete(false);
			}, 3000);
			setTimeout(() => {
				setErrorMessage(
					error?.response?.data?.error || "Error deleting project"
				);
				addToast(`${errorMessage}`, "error", 3000);
				// setShowErrorToast(true);
			}, 3000);
			// setTimeout(() => {
			// 	setShowErrorToast(false);
			// }, 4000);

			refetchProjects();
		}
	};

	return (
		<>
			<DashboardWrapper>
				{loading ? (
					<div className="flex bg3 flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md">
						<LoadingComponent />
					</div>
				) : (
					<div className="flex innerFrameBg flex-col gap-8 w-full h-full pt-8 px-8 mt-8 border border-gray-2/30 rounded-md">
						<div className="flex items-center justify-between">
							<div className="flex gap-1 items center">
								<BackIcon
									className={`hover:text-gray-5 mr-3`}
									onClick={handleBack}
								/>

								<DocTextIcon />
								<h1 className="text-[20px] font-semibold">
									{formattedProjectName}
								</h1>
							</div>
							<div>
								<p className="text-gray-3 text-sm">Created {timeAgo}</p>
							</div>
						</div>

						<div className="w-full">
							<div className="flex items-center justify-between gap-4 bg5 border-0.5 border-gray-3/20 px-4 py-2 rounded-md text-gray-1 text-sm">
								<div className="flex gap-2">
									<button
										disabled={tasks.length > 0 ? false : true}
										onClick={handleDownloadCSV}
										className={`${
											tasks.length > 0
												? "hover:bg-black-1"
												: "opacity-30 cursor-not-allowed"
										} flex items-center rounded-md gap-2 py-3 px-2 `}>
										<CSVIcon />
										Download .CSV
									</button>
									<button
										disabled={tasks.length > 0 ? false : true}
										onClick={openJiraModal}
										className={`${
											tasks.length > 0
												? "hover:bg-black-1"
												: "opacity-30 cursor-not-allowed"
										} flex items-center rounded-md gap-2 py-3 px-2`}>
										<JiraIcon />
										Send project to Jira
									</button>
								</div>
								<button
									onClick={openConfirmDelete}
									className="flex hover:bg-black-1 items-center gap-2 py-3 px-2 rounded-lg text-error-2">
									<TrashIcon />
									Delete project
								</button>
							</div>
						</div>

						{tasks.length > 0 ? (
							<div className="py-5 px-5 rounded-md flex items-center gap-4 justify-between w-full bg4">
								<div className="w-full flex flex-col gap-2">
									<h1 className="text-[20px] font-semibold">Tickets</h1>

									<div className="flex items-start gap-4 justify-between w-full">
										<p className="text-[14px] w-full text-gray-6">
											You can edit the tickets to suit your project needs before
											adding them to your project management tools.
										</p>
									</div>
								</div>
							</div>
						) : null}

						{newTasks.length > 0 ? (
							<div className="flex flex-col gap-4 overflow-scroll pb-5">
								{newTasks.map((task, index) => (
									<div key={index}>
										<TicketFull
											key={index}
											index={index}
											task={task}
											tasks={newTasks}
											onDeleteTicket={() => handleDeleteTicket(index)}
											taskId={taskId}
											showTicketPage={showTicketPage}
											setShowTicketPage={setShowTicketPage}
										/>
									</div>
								))}
							</div>
						) : (
							<Empty
								message={`No tickets in this project.`}
								text={`You currently have no tickets in this project.`}
							/>
						)}
					</div>
				)}
				{showJiraModal && (
					<SendToJiraModal
						projectId={projectId}
						type={"project"}
						onClose={closeJiraModal}
					/>
				)}
				{showErrorToast && <ErrorToast message={errorMessage} />}
			</DashboardWrapper>
			{confirmDelete && (
				<ConfirmDelete
					type={`project`}
					isDeleting={isDeleting}
					onDelete={handleDeleteProject}
					onClose={closeConfirmDelete}
				/>
			)}
			{showSuccessToast && (
				<SuccessToast message={`Project deleted successfully`} />
			)}
		</>
	);
}
