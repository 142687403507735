import React, { useState } from "react";
import BackIcon from "@/icons/BackIcon";
import EditIcon from "@/icons/EditIcon";
import TrashIcon from "@/icons/TrashIcon";
import SendToJiraModal from "@/components/modals/SendToJiraModal";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "@/components/ui/DashboardWrapper";
import WebAppService from "@/services/WebAppService";
import GlobalButton from "@/components/ui/GlobalButton";
import ConfirmSave from "@/components/modals/ConfirmSave";

export default function TicketBody({
	projectDetails,
	clickedTicketIndex,
	showProjectPage,
	setShowProjectPage,
}) {
	const [showJiraModal, setShowJiraModal] = useState(false);
	const [confirmSave, setConfirmSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const tasks = projectDetails?.tasks || [];
	const projectId = projectDetails?.id;
	const navigate = useNavigate();

	// Initialize state with values from clickedTicket
	const clickedTicket = tasks[clickedTicketIndex] || {};
	const [editedTitle, setEditedTitle] = useState(clickedTicket.title || "");
	const [editedDescription, setEditedDescription] = useState(
		clickedTicket.description
	);
	const [editedAcceptanceCriteria, setEditedAcceptanceCriteria] = useState(
		clickedTicket.acceptance_criteria
	);

	const openJiraModal = () => {
		setShowJiraModal(true);
	};

	const closeJiraModal = () => {
		setShowJiraModal(false);
	};

	const openConfirmSave = () => {
		setConfirmSave(true);
	};
	const closeConfirmSave = () => {
		setConfirmSave(false);
	};

	const backClick = () => {
		setShowProjectPage(true);
	};

	const handleEditClick = () => {
		setEditMode(true);
	};

	const handleSaveClick = async () => {
		// Update the ticket's description and acceptance criteria with edited values
		const updatedTasks = [...tasks];
		updatedTasks[clickedTicketIndex].title = editedTitle;
		updatedTasks[clickedTicketIndex].description = editedDescription;
		updatedTasks[clickedTicketIndex].acceptance_criteria =
			editedAcceptanceCriteria;

		setIsSaving(true);

		try {
			const saveEditedProjectResponse = await WebAppService.saveEditedProject(
				projectId,
				{
					tasks: updatedTasks,
					// jira_project_key: "string",
					// name: "LearnUp",
					// description: "string",
				}
			);
		} catch (error) {}

		setIsSaving(false);
		closeConfirmSave();
		setEditMode(false);
	};

	const handleCancel = () => {
		setEditMode(false);
		// Reset edited values to clickedTicket values
		setEditedTitle(clickedTicket.title || "");
		setEditedDescription(clickedTicket.description || "");
		setEditedAcceptanceCriteria(clickedTicket.acceptance_criteria || "");
	};

	return (
		<DashboardWrapper>
			<div className="flex innerFrameBg flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md overflow-scroll leading-6">
				<div
					className={`flex items-center justify-between gap-4  ${
						editMode ? "" : "border-0.5 border-gray-3/20 bg5"
					}  px-4 py-2 rounded-md text-gray-1 text-sm`}>
					<div className="flex gap-4 w-full">
						{!editMode && (
							<div className="flex items-center justify-between w-full">
								<button
									onClick={handleEditClick}
									className="flex items-center rounded-md gap-2 py-3 px-2 hover:bg-black-1 ">
									<EditIcon />
									Edit ticket
								</button>
								<button className="flex items-center text-error-2 rounded-md gap-2 py-3 px-2 hover:bg-black-1 ">
									<TrashIcon />
									Delete ticket
								</button>
							</div>
						)}
						{editMode && (
							<div className="flex justify-between w-full items-center gap-4 py-2">
								<div>
									<h1 className="font-semibold text-[20px] text-white-1">
										Edit Tickets
									</h1>
								</div>
								<div className="flex items-center gap-2">
									<div>
										<GlobalButton
											onClick={openConfirmSave}
											variant={"blue"}
											state={"default"}
											size={"sm"}>
											Save changes
										</GlobalButton>
									</div>

									<button
										className="flex items-center gap-2 py-2 px-4 rounded-full border border-error-1 text-error-1 min-w-24 text-center justify-center font-semibold"
										onClick={handleCancel}>
										Cancel
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center ">
					{editMode ? null : (
						<BackIcon
							className={`hover:text-gray-5 mr-3`}
							onClick={backClick}
						/>
					)}
					{editMode ? (
						<input
							value={editedTitle}
							onChange={(e) => setEditedTitle(e.target.value)}
							className="text-[20px] font-semibold placeholder:text-gray-2 w-full h-fit bg-transparent rounded-md py-[10px] px-[14px] focus:outline-none border-1 border-black-1"
						/>
					) : (
						<h1 className="text-[20px] font-semibold">{clickedTicket.title}</h1>
					)}
				</div>
				<div className="flex flex-col gap-4 bg5 border-0.5 border-gray-2/30 p-6 h-fit overflow-scroll rounded-md">
					<div className="flex flex-col gap-2 pb-4 border-b border-gray-2">
						<h3 className="text-blue-3 font-semibold">Ticket description:</h3>
						{editMode ? (
							<textarea
								value={editedDescription}
								onChange={(e) => setEditedDescription(e.target.value)}
								className="placeholder:text-gray-2 w-full h-[100px] bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none"
							/>
						) : (
							<p
								className="text-gray-1"
								dangerouslySetInnerHTML={{
									__html: editedDescription?.replace(/\n/g, "<br>"),
								}}
							/>
						)}
					</div>

					<div className="flex flex-col gap-2 pb-4 border-b border-gray-2">
						<h3 className="text-blue-3 font-semibold">Acceptance criteria: </h3>
						{editMode ? (
							<textarea
								value={editedAcceptanceCriteria}
								onChange={(e) => setEditedAcceptanceCriteria(e.target.value)}
								className="placeholder:text-gray-2 w-full h-[100px] bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none"
							/>
						) : (
							<p
								className="text-gray-1"
								dangerouslySetInnerHTML={{
									__html: editedAcceptanceCriteria?.replace(/\n/g, "<br>"),
								}}
							/>
						)}
					</div>
				</div>
			</div>
			{confirmSave && (
				<ConfirmSave
					onClose={closeConfirmSave}
					onSave={handleSaveClick}
					isSaving={isSaving}
				/>
			)}

			{showJiraModal && (
				<SendToJiraModal
					type={"ticket"}
					onClose={closeJiraModal}
				/>
			)}
		</DashboardWrapper>
	);
}
